import React, { useEffect } from "react";
import parse from "html-react-parser";
import Container from "../../layout/container";
import { graphql, Link } from "gatsby";
import BlogSidebar from "../../components/blog-details/Sidebar/BlogSidebar";
import Meta from "../../components/seo/Meta";
import moment from "moment";
import "moment/locale/ja";

const NewsDetails = (props) => {
  const { data } = props;
  const regEx = /\/uploads\//g;
  const imageRegEx = /<img/g;
  const baseUrl = `https://strapi-admin.harness.digitalstacks.net`;
  const newsDetails = data?.strapi?.news?.data?.[0]?.attributes;
  const latestNews = data?.latestNews?.news?.data;
  const { pageContext } = props;
  const siteUrl = props?.data?.allSite?.nodes[0]?.siteMetadata?.siteUrl;
  const assetsBaseUrl =
    props?.data?.allSite?.nodes[0]?.siteMetadata?.assetsBaseUrl;
  const metaData = {
    title: `${newsDetails.title}｜Harness正規販売代理店 DXable`,
    image: `${assetsBaseUrl}${newsDetails?.seo?.feature_image?.data?.attributes?.url}`,
    url: `${siteUrl}${props.path}`,
  };
  moment.locale("ja");

  useEffect(() => {
    if (typeof window !== "undefined") {
      document.querySelectorAll(".blog-details a").forEach((link) => {
        if (
          !link.href.includes("http://localhost:8000") ||
          !link.href.includes("https://harness.digitalstacks.net") ||
          !link.href.includes("https://harness.dxable.com")
        ) {
          link.setAttribute("target", "_blank");
        }
      });
    }
  }, []);

  return (
    <>
      <Meta meta={metaData} />

      <Container>
        <div className="py-0 hn-container">
          <div className="blog-details">
            <div className="blog-details__body">
              <div className="blog-details__body__sub-data">
                <p className="blog-details__body__sub-data__date">
                  {moment(newsDetails?.releaseDate).format("LL")}
                </p>
              </div>
              <h1 className="blog-details__body__title">
                {newsDetails?.title}
              </h1>
              <div className="blog-details__body__wrapper">
                <div className="blog-details__body__wrapper__content">
                  {parse(
                    newsDetails?.content
                      .replaceAll(regEx, `${baseUrl}/uploads/`)
                      .replaceAll(imageRegEx, `<img loading="lazy" `)
                  )}
                  <div className="blog-details__body__wrapper__content__cta">
                    {pageContext?.previousPageId !== "" && (
                      <Link to={`${pageContext?.previousPagePath}`}>
                        &lt; 前へ
                      </Link>
                    )}
                    {pageContext?.nextPageId !== "" && (
                      <Link
                        to={`${pageContext?.nextPagePath}`}
                        className="ml-auto"
                      >
                        次へ &gt;
                      </Link>
                    )}
                  </div>
                </div>
                <div className="blog-details__body__wrapper__sidebar">
                  <BlogSidebar latestBlogs={latestNews} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default NewsDetails;

export const query = graphql`
  query NewsDetails($pageId: String!) {
    strapi {
      news(filters: { slug: { eq: $pageId } }) {
        data {
          attributes {
            slug
            title
            content
            releaseDate
            seo {
              feature_image {
                data {
                  attributes {
                    url
                  }
                }
              }
              description
            }
          }
        }
      }
    }
    latestNews: strapi {
      news(pagination: { limit: 10 }, sort: "releaseDate:desc") {
        data {
          attributes {
            slug
            title
            content
            releaseDate
          }
        }
      }
    }
    allSite {
      nodes {
        siteMetadata {
          siteUrl
          assetsBaseUrl
        }
      }
    }
  }
`;
